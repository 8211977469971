import React from 'react';
import { Header } from '../Header/Header';

export const ComputerCourse = () => {
  return (
    <div id='computer-courses'>
      <Header />
      <section className='ttm-row introduction-section break-1199-colum clearfix'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 content-area'>
              <div className='ttm-service-single-content-area'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='ttm-service-description'>
                      <div className='section-title with-desc text-center clearfix'>
                        <div className='container'>
                          <h2 className='title'>Career Courses</h2>
                        </div>
                      </div>
                      <div className='container'>
                        <div className='sep_holder_box width-100 mb-20'>
                          <span className='sep_holder'>
                            <span className='sep_line'></span>
                          </span>
                          <span className='sep_holder'>
                            <span className='sep_line'></span>
                          </span>
                        </div>

                        <div className='row mt-15 mb-15'>
                          <div className='col-sm-6'>
                            <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey'>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Adv.Dip.In Corporate Finance (ADCF-01)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Dip.In Printing Technology (DPT-02)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Dip.In Airport Operations & Hospitality
                                  (DAPH-03)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Dip.In Pre Primary Faculty Training Programme
                                  (DPPFTP-04)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Diploma In Building Animation (DBA-10)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (DCA-11) Diploma in Computer Application (Fast
                                  Tarck)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (DCHM-12)Diploma in Computer Hardware
                                  Maintenance
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (PDNT-17) Professional Dot Net Technology
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (PGD-18)Professional In Graphic Designing
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (DGAV-19)Diploma In
                                  Graphics,Animation&VisualFX
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className='col-sm-6'>
                            <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey'>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Adv.Dip.In Computer Hardware Maintenance &
                                  Networking (ADCHMN-05)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Adv.Dip.In Graphics , Animation&Visual FX
                                  (ADGAV-06)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Adv.Dip.In Interior Designing (ADID-07)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Dip.In Computer Faculty Training
                                  Programme;(DCFTP-08)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Data Entry Operator And Office Automation
                                  (DEOA-09)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (DFBI-13)Diploma in Finance,Banking&Insurance
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (DFFA-14) Diploma In Financial&Foreign
                                  Accounting
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (PGDCA-15) Post Graduate Diploma In Computer
                                  Application[fast tarck]
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (PGDA-16) Professional in 3D Animation
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='ttm-row introduction-section break-1199-colum clearfix'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 content-area'>
              <div className='ttm-service-single-content-area'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='ttm-service-description'>
                      <div className='section-title with-desc text-center clearfix'>
                        <div className='container'>
                          <h2 className='title'>Certificate Courses</h2>
                        </div>
                      </div>
                      <div className='container'>
                        <div className='sep_holder_box width-100 mb-20'>
                          <span className='sep_holder'>
                            <span className='sep_line'></span>
                          </span>
                          <span className='sep_holder'>
                            <span className='sep_line'></span>
                          </span>
                        </div>

                        <div className='row mt-15 mb-15'>
                          <div className='col-sm-6'>
                            <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey'>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Revit Architecture Expert(RAE-20)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (AVE-44) Audio Video Editing
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Data Mining(DM-21)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (CE-45) Cad Expert
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  IoT(IOT-22)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (CPP-46)C Plus Plus programing Language
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Digital Literacy(DL-23)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (C-47) C Programmimg Language
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Ethical Hacking(EH-24)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (EE-48) Edit Expert
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (J-55) Java Programming
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  SAP(SAP-32)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (MAX-56)Max Expert
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Mobile Servicing & Chiplevel(MSC-33)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (ME-57) Maya Expert
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  CCTV (CCTV-34)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (MS-58) MS Office
                                </span>
                              </li>
                              <li>
                                <li>
                                  <i className='fa fa-arrow-circle-right'></i>
                                  <span className='ttm-list-li-content'>
                                    Beauty Culture (BC-35)
                                  </span>
                                </li>
                                <li>
                                  <i className='fa fa-arrow-circle-right'></i>
                                  <span className='ttm-list-li-content'>
                                    (OA-59) Office Automation
                                  </span>
                                </li>
                                <li>
                                  <i className='fa fa-arrow-circle-right'></i>
                                  <span className='ttm-list-li-content'>
                                    Logistics & Supply Chain(LSC-36)
                                  </span>
                                </li>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (VB-64) Visual Basic
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Adobe Corel Draw(ACD-41)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (WDE-65) Web Designing Expert
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Adobe Flash(AF-42)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Adobe Corel Draw(ACD-41)
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className='col-sm-6'>
                            <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey'>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Artifical Intelligence(AI-25)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (EEE-49) Efx Expert
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Python(P-26)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (EDP-50)Expert In Desktop Publishing
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Robotics (R-27)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (EGD-51) Expert In Graphic Designing
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Autocad Electrical (AE-28)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (EM-52) Expert In Multimedia
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Autocad Mechanical (AM-29)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (FCP-53) Final Cut Pro
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Linux Administration (LA-30)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (IE-54) Interactive Expert
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  3D Printing(3DPE-31)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (OR-60)Oracle
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Accupunture Science(AC-37)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (PM-61) PHP+Mysql
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Fashion Design(FD-38)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (SQLs-62) SQL Server
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Laptop Servicing(LS-39)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (T-63) Tally
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Desktop Publishing(DP-40)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (WDE-65) Web Designing Expert
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (Z-66)Z-Brush
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Adobe Photoshop(AP-43)
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='ttm-row introduction-section break-1199-colum clearfix'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 content-area'>
              <div className='ttm-service-single-content-area'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='ttm-service-description'>
                      <div className='section-title with-desc text-center clearfix'>
                        <div className='container'>
                          <h2 className='title'>Vaccation Courses</h2>
                        </div>
                      </div>
                      <div className='container'>
                        <div className='sep_holder_box width-100 mb-20'>
                          <span className='sep_holder'>
                            <span className='sep_line'></span>
                          </span>
                          <span className='sep_holder'>
                            <span className='sep_line'></span>
                          </span>
                        </div>

                        <div className='row mt-15 mb-15'>
                          <div className='col-sm-6'>
                            <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey'>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Animation Junior (AJ-67)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (DTP-71) Desk Top Publishing- Junior
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  C-apt Junior (CJ-68)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (PJ-72) Programmer-Junior
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Computer Wiz (CW-71)
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className='col-sm-6'>
                            <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey'>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  C-apt Kids (CK-69)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (VE-73) Viedo- Editor Junior
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  Computer Operator- Junior (CO-70)
                                </span>
                              </li>
                              <li>
                                <i className='fa fa-arrow-circle-right'></i>
                                <span className='ttm-list-li-content'>
                                  (WD-74) Web Designer-Junior
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
