import React from "react";

export const Notfound = () => {
  return (
    <div>
      <div className="site-main">
        <section className="error-404">
          <div className="ttm-big-icon">
            <i className="fa fa-thumbs-o-down"></i>
          </div>
          <header className="page-header">
            <h1 className="page-title">404 ERROR</h1>
          </header>
          <div className="page-content">
            {" "}
            <p>
              This page may have been moved or deleted. Be sure to check your
              spelling.
            </p>
          </div>
          <a
            className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor"
            href="/"
          >
            Back To Home
          </a>
        </section>
      </div>
    </div>
  );
};
