import React from "react";
import { Header } from "./Header/Header";
import { ScrollingNews } from "./Header/ScrollingNews";
import Home from "./Home/Home";
import Testimational from "./Testimational/Testimational";

const Main = () => {
  return (
    <div>
    {/* main */}
      <Header />
      <ScrollingNews />
      <Home />
      <Testimational />
    </div>
  );
};

export default Main;
