import React from "react";

export const ScrollingNews = () => {
  return (
    <div className="scrolling__news__wrapper">
      <div className="label">Announcement</div>
      <div className="text__wrapper">
        {/* <p>
          New admission started for computer courses. Paramedical and technical
          courses also. All classes are conducted adhering to the COVID – 19
          regulations. For more details contact 9447183114.
        </p> */}
        <p>
          Admission started for spoken english classes. We are providing both
          regular and crash courses for 3 months of duration. For more
          informations contact - <b>0475 2274191</b>
        </p>
      </div>
    </div>
  );
};
