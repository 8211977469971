import React from "react";
import "./Comment.css";

export const Comments = () => {
  return (
    <div>
      <div id="content-slider">
        <div class="wrap-slider">
          <h1 class="main-title">Quotes</h1>
          <div class="slider">
            <div class="inset">
              <figure>
                <img src="images\bg-image\12.webp" alt="" id="i-1" class="f" />
              </figure>

              <figure>
                <img src="images\bg-image\13.webp" alt="" id="i-2" class="f" />
              </figure>

              <figure>
                <img src="images\bg-image\14.webp" alt="" id="i-3" class="f" />
              </figure>

              <figure>
                <img src="images\bg-image\15.webp" alt="" id="i-4" class="f" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
