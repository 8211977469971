import React from "react";

export const Footer = () => {
  return (
    <div>
      <footer className="footer widget-footer clearfix">
        <div className="first-footer ttm-bgcolor-skincolor">
          <div className="container">
            <div className="row">
              <div className="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <aside className="widget widget-text">
                  <div className="featured-icon-box iconalign-before-heading">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                        <i className="fa fa-phone"></i>
                      </div>
                    </div>
                    <div className="featured-title">
                      <h5>0475-2274191</h5>
                      <h4>Have a question? call us now</h4>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <aside className="widget widget-text">
                  <div className="featured-icon-box iconalign-before-heading">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                        <i className="fa fa-envelope-o"></i>
                      </div>
                    </div>
                    <div className="featured-title">
                      <h5>allianzinstitute@gmail.com</h5>
                      <h4>Need support? Drop us an email</h4>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <aside className="widget widget-text">
                  <div className="featured-icon-box iconalign-before-heading">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                        <i className="fa fa-clock-o"></i>
                      </div>
                    </div>
                    <div className="featured-title">
                      <h5>Mon – Sat 09:00 – 5:00</h5>
                      <h4>We are open on</h4>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
        <div className="second-footer ttm-textcolor-white">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_text clearfix">
                  <h3 className="widget-title">About Allianz Institute</h3>
                  <div className="textwidget widget-text">
                    Allianz Institute is a NSDC approved Training center in
                    Anchal offering tuition for Paramedical, technical and
                    computer courses. It is an autonomous institution aimed at
                    imparting technical education. In the increasingly
                    competitive environment, we offer a guarantee of quality,
                    value and intellectual rigor to your academic expedition to
                    success.
                    <br></br>
                    <br></br>
                    <br></br>{" "}
                    <div className="social-icons social-hover">
                      <ul className="list-inline">
                        <li className="social-facebook">
                          <a
                            className="tooltip-top"
                            target="_blank"
                            href="https://www.facebook.com/allianzcollege/"
                            data-tooltip="Facebook"
                          >
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="social-twitter">
                          <a
                            className="tooltip-top"
                            target="_blank"
                            href="#"
                            data-tooltip="Twitter"
                          >
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li className="social-gplus">
                          <a
                            className=" tooltip-top"
                            target="_blank"
                            href="#"
                            data-tooltip="Google+"
                          >
                            <i
                              className="fa fa-google-plus"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="social-linkedin">
                          <a
                            className=" tooltip-top"
                            target="_blank"
                            href="#"
                            data-tooltip="LinkedIn"
                          >
                            <i
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_nav_menu clearfix">
                  <h3 className="widget-title">OUR COURSES</h3>
                  <ul id="menu-footer-quick-links">
                    <li>
                      <a>B.Sc MLT</a>
                    </li>
                    <li>
                      <a>B.Sc RIT</a>
                    </li>
                    <li>
                      <a>DMLT</a>
                    </li>
                    <li>
                      <a>DMRT</a>
                    </li>
                    <li>
                      <a>DRIT</a>
                    </li>
                    <li>
                      <a>X-Ray, CT Scan, MRI, ECG</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget style2 widget-out-link clearfix">
                  <h3 className="widget-title">Technical Courses</h3>
                  <ul className="widget-post ttm-recent-post-list">
                    <li>
                      <a>
                        <img src="images/portfolio/9.webp" alt="post-img" />
                      </a>
                      <a>DIPLOMA IN CIVIL ENGINEERING</a>
                    </li>
                    <li>
                      <a>
                        <img src="images/portfolio/10.webp" alt="post-img" />
                      </a>
                      <a>DIPLOMA IN MECHANICAL ENGINEERING</a>
                    </li>
                    <li>
                      <a>
                        <img src="images/portfolio/11.webp" alt="post-img" />
                      </a>
                      <a>DIPLOMA IN ELECTRICAL ENGINEERING</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                <div className="widget widget_nav_menu clearfix">
                  <h3 className="widget-title">OUR SERVICES</h3>
                  <ul id="menu-footer-quick-links">
                    <li>
                      <a>It-Ites Sector</a>
                    </li>
                    <li>
                      <a>Apparel Sector</a>
                    </li>
                    <li>
                      <a>Electronics Sector</a>
                    </li>
                    <li>
                      <a>Logistics Sector</a>
                    </li>
                    <li>
                      <a>Beauty and Wellness Sector</a>
                    </li>
                    <li>
                      <a> HealthCare Sector</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text ttm-textcolor-white">
          <div className="container">
            <div className="row copyright">
              <div className="col-md-12">
                <span>
                  © 2020 allianzinstitute.com. All Right Reserved By{" "}
                  <a href="https://forcodetech.com/"> Forcodetech</a>
                </span>
              </div>
              <div className="col-md-12">
                <ul id="menu-footer-menu" className="footer-nav-menu"></ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
