import React from "react";
import { Header } from "../Header/Header";
export const AboutUs = () => {
  return (
    <div>
      <Header />

      <div className="ttm-page-title-row">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">About Us</h1>
                  <h3 className="subtitle"> ALLIANZ INSTITUTE</h3>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                      <span>About Us</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <section className="ttm-row introduction-section break-1199-colum clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-sm-12">
                <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg res-1199-ptb-200 ttm-reset-content-center-1199">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content"></div>
                </div>
              </div>
              <div className="col-lg-7 col-sm-12">
                <div className="pl-60 pb-20 res-1199-pl-0 res-1199-pt-40 res-1199-pb-0">
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h2 className="title">WELCOME TO Allianz Institute</h2>
                    </div>
                  </div>
                  <div className="mb-30 clearfix">
                    <p>
                      Allianz Institute is a NSDC approved Training center in
                      Anchal offering tuition for Paramedical, technical and
                      computer courses. It is an autonomous institution aimed at
                      imparting technical education. In the increasingly
                      competitive environment, we offer a guarantee of quality,
                      value and intellectual rigor to your academic expedition
                      to success.
                    </p>
                  </div>

                  <div className="sep_holder_box pt-25 mb-45 res-991-mb-20">
                    <span className="sep_holder">
                      <span className="sep_line"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ttm-row testimonial-section bg-img2 ttm-bg ttm-bgimage-yes ttm-bgcolor-darkgrey">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 col-md-12">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">MISSION & VISION</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <div className="defalut-view-testimonial">
                  <div
                    className="testimonial-slide owl-carousel mb-20 res-991-mb-0"
                    data-item="1"
                    data-nav="true"
                    data-dots="false"
                    data-auto="false"
                  >
                    <div className="testimonials">
                      <div className="testimonial-content">
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">
                            <img
                              className="img-center"
                              src="images/testimonial/01.jpg"
                              alt="testimonial-img"
                            />
                          </div>
                        </div>
                        <blockquote>
                          Prior to my stay at forcode, I was aware of their
                          stellar reputation and multiple friends commented
                          about how lucky I was to have my surgery scheduled
                          there. As a result my expectations were high and yet
                          the kind and professional staff with excellent patient
                          care surpassed them.
                        </blockquote>
                        <div className="star-ratings">
                          <ul className="rating">
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                          </ul>
                        </div>
                        <div className="testimonial-caption">
                          <h5>RAshley Foster</h5>
                          <label>Patient</label>
                        </div>
                      </div>
                    </div>

                    <div className="testimonials">
                      <div className="testimonial-content">
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">
                            <img
                              className="img-center"
                              src="images/testimonial/02.jpg"
                              alt="testimonial-img"
                            />
                          </div>
                        </div>
                        <blockquote>
                          Great co workers, really good environment and
                          excellent patient care. They are continuously
                          innovating themselves which is why they remain a
                          premier dental clinic.This was the cleanest medical
                          establishment I've ever been in. They just loved the
                          welcoming and warm atmosphere in there.
                        </blockquote>
                        <div className="star-ratings">
                          <ul className="rating">
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                          </ul>
                        </div>
                        <div className="testimonial-caption">
                          <h5>Alan Sears</h5>
                          <label>Patient</label>
                        </div>
                      </div>
                    </div>

                    <div className="testimonials">
                      <div className="testimonial-content">
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">
                            <img
                              className="img-center"
                              src="images/testimonial/03.jpg"
                              alt="testimonial-img"
                            />
                          </div>
                        </div>
                        <blockquote>
                          The doctors and nurses and aides were excellent. The
                          food was excellent. I am feeling fine and very lucky,
                          back to my old self again and it feels great. Thank
                          God for clinics such as yours. I appreciate all your
                          kindness and good care you gave me is beyond my
                          expectations they wonder for me.
                        </blockquote>
                        <div className="star-ratings">
                          <ul className="rating">
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                            <li>
                              <i className="fa fa-star"></i>
                            </li>
                          </ul>
                        </div>
                        <div className="testimonial-caption">
                          <h5>Raymon Myers</h5>
                          <label>Patient</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </section>

        <section className="ttm-row multi-section break-991-colum clearfix">
          <div className="container">
            <div className="row mt_190 res-1199-mlr-15 res-991-mt-50 box-shadow2 no-gutters">
              <div className="col-md-5">
                <div className="col-bg-img-three ttm-bg ttm-col-bgimage-yes">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content"></div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="spacing-7 ttm-bgcolor-white">
                  <div className="row mt_20">
                    <div className="col-md-4">
                      <div className="col-bg-img-five ttm-col-bgimage-yes ttm-bg mr_15 res-991-mr-0">
                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                          <div className="col-bg-img-five"></div>
                        </div>
                        <div className="layer-content"></div>
                      </div>
                      <img
                        src="images/bg-image/col-bgimage-5.webp"
                        className="ttm-equal-height-image"
                        alt="bg-image"
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="featured-icon-box res-767-pt-15">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Our Mission</h5>
                          </div>
                          <div className="featured-desc">
                            <p>
                              We provide training for seven sector skill
                              councils under Gramin Shiksha Pvt Ltd. which are:
                              <ul>
                                <li>1) It-Ites Sector </li>
                                <li>2) Beauty and Wellness Sector </li>
                                <li>3) Apparel Sector</li>
                                <li>4) Electronics Sector</li>
                                <li>5) Logistics Sector</li>
                                <li>6) HealthCare Sector</li>
                                <li>
                                  7) Management and Entrepreneurship Sector
                                </li>
                              </ul>
                            </p>
                            <p>
                              We also conduct classNamees for PSC approved
                              computer course and undertake tuition classNamees
                              for WHRDE collaborated Universities.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-30">
                    <div className="col-md-4">
                      <br></br> <br></br>
                      <div className="col-bg-img-six ttm-col-bgimage-yes ttm-bg mr_15">
                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                          <div className="col-bg-img-six"></div>
                        </div>
                        <div className="layer-content"></div>
                      </div>
                      <img
                        src="images/bg-image/col-bgimage-6.webp"
                        className="ttm-equal-height-image"
                        alt="bg-image"
                      />
                    </div>
                    <div className="col-md-8">
                      <br></br> <br></br>
                      <div className="featured-icon-box res-767-pt-15">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Our Vision</h5>
                          </div>
                          <div className="featured-desc">
                            <p>
                              Allianz Institute emphasizes the all-round
                              development of its students. It aims at producing
                              not only good professionals but also good and
                              worthy citizens of a great country, aiding in its
                              overall progress and development. Allianz
                              Institute is an approved training centre of Gramin
                              Shiksha Pvt Ltd under Fee based model of National
                              Skill Development Corporation (NSDC).
                            </p>
                            <br></br>
                            <p>
                              NSDC is a not-for-profit public limited company
                              incorporated on July 31, 2008 under the Government
                              of India through Ministry of Skill Development &
                              Entrepreneurship (MSDE). NSDC aims to promote
                              skill development by catalysing creation of large,
                              quality and for-profit vocational institutions.
                              NSDC acts as a catalyst in skill development by
                              providing funding to enterprises, companies and
                              organizations that provide skill training.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row introduction-section break-1199-colum clearfix">
          <div className="container">
            <div className="row">
              <div className="col-12 content-area">
                <div className="ttm-service-single-content-area">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="ttm-service-description">
                        <div className="section-title with-desc text-center clearfix">
                          <div className="container">
                            <h2 className="title">OUR FACILITIES</h2>
                          </div>
                        </div>
                        <div className="container">
                          <div className="sep_holder_box width-100 mb-20">
                            <span className="sep_holder">
                              <span className="sep_line"></span>
                            </span>
                            <span className="sep_holder">
                              <span className="sep_line"></span>
                            </span>
                          </div>

                          <div className="row mt-15 mb-15">
                            <div className="col-sm-6">
                              <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey">
                                <li>
                                  <i className="fa fa-arrow-circle-right"></i>
                                  <span className="ttm-list-li-content">
                                    Well Experienced Staff Members.
                                  </span>
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right"></i>
                                  <span className="ttm-list-li-content">
                                    Well equipped lab facilities.
                                  </span>
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right"></i>
                                  <span className="ttm-list-li-content">
                                    Library facilities.
                                  </span>
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right"></i>
                                  <span className="ttm-list-li-content">
                                    Seprate Hostel facilties for Boys/Girls.
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-sm-6">
                              <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey">
                                <li>
                                  <i className="fa fa-arrow-circle-right"></i>
                                  <span className="ttm-list-li-content">
                                    Scholarrship for well scored students.
                                  </span>
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right"></i>
                                  <span className="ttm-list-li-content">
                                    Monthly Exams will be conducted to develop
                                    the educational skill of students.
                                  </span>
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right"></i>
                                  <span className="ttm-list-li-content">
                                    Annual educational tours will be arranged
                                    for all branches.
                                  </span>
                                </li>
                                <li>
                                  <i className="fa fa-arrow-circle-right"></i>
                                  <span className="ttm-list-li-content">
                                    Campus placement for leading Laborataries.
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default AboutUs;
