import React from "react";

const Home = () => {
  return (
    <div>
      <div
        id="rev_slider_4_2_wrapper"
        className="rev_slider_wrapper fullwidthbanner-container slide-overlay"
        data-alias="classic4export"
        data-source="gallery"
      >
        <div
          id="rev_slider_4_2"
          className="rev_slider fullwidthabanner"
          data-version="5.4.8.2"
        >
          <ul>
            <li
              data-index="rs-7"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-thumb="images/slides/slider-mainbg-006.webp"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="images/slides/slider-mainbg-006.webp"
                alt=""
                title="mainslider-bg002"
                width="1920"
                height="750"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />

              <div
                className="tp-caption slide-main-text"
                id="slide-7-layer-3"
                data-x="['right','right','right','center']"
                data-hoffset="['120','110','80','0']"
                data-y="['top','top','top','top']"
                data-voffset="['191','181','61','65']"
                data-fontsize="['75','75','60','45']"
                data-lineheight="['80','80','60','40']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(255,255,255)','rgb(255,255,255)','rgb(255,255,255)','rgb(255,255,255)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":230,"speed":600,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                Welcome To{" "}
              </div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-7-layer-4"
                data-x="['right','right','right','center']"
                data-hoffset="['50','40','30','0']"
                data-y="['top','top','top','top']"
                data-voffset="['265','254','121','109']"
                data-fontsize="['75','75','60','45']"
                data-lineheight="['80','80','60','45']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(255, 255, 255)','rgb(255, 255, 255)','rgb(255, 255, 255)','rgb(255, 255, 255)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":400,"speed":800,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                Allianz{" "}
                <strong className="ttm-textcolor-skincolor">Institute</strong>{" "}
              </div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-7-layer-5"
                data-x="['right','center','right','right']"
                data-hoffset="['50','273','-320','-320']"
                data-y="['top','top','top','top']"
                data-voffset="['362','354','187','149']"
                data-fontsize="['23','23','18','15']"
                data-lineheight="['25','25','18','15']"
                data-fontweight="['200','200','200','200']"
                data-color="['rgb(255, 255, 255)','rgb(255, 255, 255)','rgb(255, 255, 255)','rgb(255, 255, 255)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":870,"speed":500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>
            </li>
            <li
              data-index="rs-6"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-thumb="images/slides/slider-mainbgs-001.webp"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="images/slides/slider-mainbgs-001.webp"
                alt=""
                title="mainslider-bg001"
                width="1920"
                height="750"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />

              <div
                className="tp-caption tp-resizeme"
                id="slide-6-layer-1"
                data-x="['left','left','left','left']"
                data-hoffset="['50','40','-220','-220']"
                data-y="['top','top','top','top']"
                data-voffset="['160','158','63','63']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":180,"speed":500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-8-layer-8"
                data-x="['left','left','center','center']"
                data-hoffset="['50','40','0','0']"
                data-y="['top','top','top','middle']"
                data-voffset="['222','195','109','-60']"
                data-fontsize="['75','75','65','40']"
                data-lineheight="['75','75','65','40']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":300,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                DREAM DON'T WORK
              </div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-8-layer-9"
                data-x="['left','left','center','center']"
                data-hoffset="['50','40','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['295','271','170','134']"
                data-fontsize="['75','75','65','40']"
                data-lineheight="['75','75','75','50']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":520,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                UNLESS YOU DO
              </div>

              <div
                className="tp-caption slide-desc-text tp-resizeme"
                id="slide-8-layer-10"
                data-x="['left','left','center','left']"
                data-hoffset="['50','40','-664','-579']"
                data-y="['top','top','top','top']"
                data-voffset="['386','362','214','233']"
                data-fontsize="['17','17','15','15']"
                data-lineheight="['27','27','25','25']"
                data-fontweight="['400','400','400','400']"
                data-color="['rgb(89, 102, 105)','rgb(89, 102, 105)','rgb(89, 102, 105)','rgb(89, 102, 105)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":810,"speed":600,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','center','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>
            </li>
            <li
              data-index="rs-6"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-thumb="images/slides/allianz.webp"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="images/slides/allianz.webp"
                alt=""
                title="mainslider-bg001"
                width="1920"
                height="750"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />

              <div
                className="tp-caption tp-resizeme"
                id="slide-6-layer-1"
                data-x="['left','left','left','left']"
                data-hoffset="['50','40','-220','-220']"
                data-y="['top','top','top','top']"
                data-voffset="['160','158','63','63']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":180,"speed":500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-8-layer-8"
                data-x="['left','left','center','center']"
                data-hoffset="['50','40','0','0']"
                data-y="['top','top','top','middle']"
                data-voffset="['222','195','109','-60']"
                data-fontsize="['75','75','65','40']"
                data-lineheight="['75','75','65','40']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":300,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-8-layer-9"
                data-x="['left','left','center','center']"
                data-hoffset="['50','40','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['295','271','170','134']"
                data-fontsize="['75','75','65','40']"
                data-lineheight="['75','75','75','50']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":520,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-desc-text tp-resizeme"
                id="slide-8-layer-10"
                data-x="['left','left','center','left']"
                data-hoffset="['50','40','-664','-579']"
                data-y="['top','top','top','top']"
                data-voffset="['386','362','214','233']"
                data-fontsize="['17','17','15','15']"
                data-lineheight="['27','27','25','25']"
                data-fontweight="['400','400','400','400']"
                data-color="['rgb(89, 102, 105)','rgb(89, 102, 105)','rgb(89, 102, 105)','rgb(89, 102, 105)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":810,"speed":600,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','center','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>
            </li>
            <li
              data-index="rs-6"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-thumb="images/slides/allianz.webp"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="images/slides/slider2.webp"
                alt=""
                title="mainslider-bg001"
                width="1920"
                height="750"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />

              <div
                className="tp-caption tp-resizeme"
                id="slide-6-layer-1"
                data-x="['left','left','left','left']"
                data-hoffset="['50','40','-220','-220']"
                data-y="['top','top','top','top']"
                data-voffset="['160','158','63','63']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":180,"speed":500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-8-layer-8"
                data-x="['left','left','center','center']"
                data-hoffset="['50','40','0','0']"
                data-y="['top','top','top','middle']"
                data-voffset="['222','195','109','-60']"
                data-fontsize="['75','75','65','40']"
                data-lineheight="['75','75','65','40']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":300,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-8-layer-9"
                data-x="['left','left','center','center']"
                data-hoffset="['50','40','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['295','271','170','134']"
                data-fontsize="['75','75','65','40']"
                data-lineheight="['75','75','75','50']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":520,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-desc-text tp-resizeme"
                id="slide-8-layer-10"
                data-x="['left','left','center','left']"
                data-hoffset="['50','40','-664','-579']"
                data-y="['top','top','top','top']"
                data-voffset="['386','362','214','233']"
                data-fontsize="['17','17','15','15']"
                data-lineheight="['27','27','25','25']"
                data-fontweight="['400','400','400','400']"
                data-color="['rgb(89, 102, 105)','rgb(89, 102, 105)','rgb(89, 102, 105)','rgb(89, 102, 105)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":810,"speed":600,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','center','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>
            </li>
            <li
              data-index="rs-6"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-thumb="images/slides/allianz.webp"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="images/slides/drt.webp"
                alt=""
                title="mainslider-bg001"
                width="1920"
                height="750"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />

              <div
                className="tp-caption tp-resizeme"
                id="slide-6-layer-1"
                data-x="['left','left','left','left']"
                data-hoffset="['50','40','-220','-220']"
                data-y="['top','top','top','top']"
                data-voffset="['160','158','63','63']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":180,"speed":500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-8-layer-8"
                data-x="['left','left','center','center']"
                data-hoffset="['50','40','0','0']"
                data-y="['top','top','top','middle']"
                data-voffset="['222','195','109','-60']"
                data-fontsize="['75','75','65','40']"
                data-lineheight="['75','75','65','40']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":300,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-main-text tp-resizeme"
                id="slide-8-layer-9"
                data-x="['left','left','center','center']"
                data-hoffset="['50','40','0','0']"
                data-y="['top','top','top','top']"
                data-voffset="['295','271','170','134']"
                data-fontsize="['75','75','65','40']"
                data-lineheight="['75','75','75','50']"
                data-fontweight="['600','600','600','600']"
                data-color="['rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)','rgb(0, 46, 58)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":520,"speed":800,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption slide-desc-text tp-resizeme"
                id="slide-8-layer-10"
                data-x="['left','left','center','left']"
                data-hoffset="['50','40','-664','-579']"
                data-y="['top','top','top','top']"
                data-voffset="['386','362','214','233']"
                data-fontsize="['17','17','15','15']"
                data-lineheight="['27','27','25','25']"
                data-fontweight="['400','400','400','400']"
                data-color="['rgb(89, 102, 105)','rgb(89, 102, 105)','rgb(89, 102, 105)','rgb(89, 102, 105)']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-visibility="['on','on','off','off']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":810,"speed":600,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','center','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
