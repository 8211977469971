import React from 'react';

const Offline = () => {
  return (
    <div id='page-offline'>
      <div className='container'>
        <h1>Are you currently offline?</h1>
        <p>
          <i className='fa fa-thumbs-o-down'></i>
        </p>
      </div>
    </div>
  );
};

export default Offline;
