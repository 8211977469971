import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

// Components
import Main from "./Components/Main";
import Footer from "./Components/Footer/Footer";
import ContactUs from "./Components/Contact/ContactUs";
import AboutUs from "./Components/About/AboutUs";
import ParaCourse from "./Components/Courses/ParaCourse";
import TechCourse from "./Components/Courses/TechCourse";
import ScrollToTop from "./Components/ScrollToTop";
import { ComputerCourse } from "./Components/Courses/ComputerCourse";
import { Notfound } from "./Components/Notfound";
import Offline from "./Components/Offline";

const App = () => {
  return !navigator.onLine ? (
    <Offline />
  ) : (
    <div>
      <HashRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/" component={Main} exact />
          <Route path="/ContactUs" component={ContactUs} exact />
          <Route path="/AboutUs" component={AboutUs} exact />
          {/* updated by radhul 17-03-2024 --start-- */}

          {/* <Route path="/ParaCourse" component={ParaCourse} exact /> */}
          {/* <Route path="/TechCourse" component={TechCourse} exact /> */}
          
          {/* updated by radhul 17-03-2024 --end-- */}

          <Route path="/ComputerCourse" component={ComputerCourse} exact />
          <Route component={Notfound} exact />
        </Switch>
      </HashRouter>
      <Footer />
    </div>
  );
};

export default App;
