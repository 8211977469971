import React from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import Comments from "../Comments/Comments";

const Testimational = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_jadwb1n",
        e.target,
        "user_y65onCSXuAI06uTgThxkw"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3500,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div className="site-main">
      <section className="ttm-row row-top-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mt_45 res-991-mt-50 ttm-bgcolor-white">
                <div className="row no-gutters">
                  <div className="col-lg">
                    <div className="featured-icon-box top-icon style4 text-center">
                      <div className="featured-icon-box-inner">
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                          <img src="images/NSCD3.ico" alt="" />
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>GSDM</h5>
                          </div>
                          <div className="ttm-di_links">
                            <a
                              href="http://graminskill.com/"
                              target="_blank"
                              className="di_link"
                            >
                              <i className="fa fa-angle-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <div className="featured-icon-box top-icon style4 text-center">
                      <div className="featured-icon-box-inner">
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                          <i class="fa fa-desktop" aria-hidden="true"></i>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Computer Course</h5>
                          </div>
                          <div className="ttm-di_links">
                            <Link to="/ComputerCourse" className="di_link">
                              <i className="fa fa-angle-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg">
                    <div className="featured-icon-box top-icon style4 text-center">
                      <div className="featured-icon-box-inner">
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                          <i class="fa fa-cogs"></i>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Technical Course</h5>
                          </div>
                          <div className="ttm-di_links">
                            <Link to="/TechCourse" className="di_link">
                              <i className="fa fa-angle-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg">
                    <div className="featured-icon-box top-icon style4 text-center">
                      <div className="featured-icon-box-inner">
                        <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                          <img src="images/whrde_icon.ico" alt="" />
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>W H R D E</h5>
                          </div>
                          <div className="ttm-di_links">
                            <a
                              href="http://www.whrde.edu.in/"
                              className="di_link"
                              target="_blank"
                            >
                              <i className="fa fa-angle-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="ttm-row introduction-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xs-12">
              <div className="pt-50 res-991-pt-0">
                <div className="section-title clearfix">
                  <div className="title-header"></div>
                </div>
                <h5>OUR PARAMEDICAL COURSES</h5>

                <p>
                  {" "}
                  (All paramedical courses are conducted by WHRDE collaborated
                  Universities and follow the rules and regulations of
                  University)
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                      <li>
                        <i className="fa fa-arrow-circle-right"></i>
                        <span className="ttm-list-li-content">DMLT</span>
                      </li>
                      <li>
                        <i className="fa fa-arrow-circle-right"></i>
                        <span className="ttm-list-li-content">DRIT</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                      <li>
                        <i className="fa fa-arrow-circle-right"></i>
                        <span className="ttm-list-li-content">DMRT</span>
                      </li>
                      <li>
                        <i className="fa fa-arrow-circle-right"></i>
                        <span className="ttm-list-li-content">
                          X-Ray, CT Scan, MRI, ECG
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-12"></div>
                </div>
                <br />
                <br />

                <h5>Our Technical Cources</h5>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                      <li>
                        <i className="fa fa-arrow-circle-right"></i>
                        <span className="ttm-list-li-content">
                          Diploma In Civil Engineering
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-arrow-circle-right"></i>
                        <span className="ttm-list-li-content">
                          Diploma In Mechanical Engineering
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-arrow-circle-right"></i>
                        <span className="ttm-list-li-content">
                          Diploma In Electrical Engineering
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-12"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              <div className="ttm_single_image-wrapper text-right">
                <img
                  className="img-fluid"
                  src="images/single-img-twel.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section
        id="appointment__form"
        className="ttm-row multi-section break-991-colum bg-img3 clearfix"
      >
        <div className="container">
          <div className="row res-1199-mlr-15 res-991-mt-50 box-shadow2 no-gutters">
            <div className="col-md-5">
              <div className="col-bg-img-three ttm-bg ttm-col-bgimage-yes">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content"></div>
              </div>
              <img
                src="images/bg-image/col-bgimage-7.jpg"
                className="ttm-equal-height-image"
                alt=""
              />
            </div>
            <div className="col-md-7">
              <div className="spacing-4 ttm-bgcolor-white">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">Get In Touch</h2>
                  </div>
                </div>
                <form
                  id="ttm-make-appoint-form"
                  className="ttm-make-appoint-form wrap-form mt_15 clearfix"
                  onSubmit={sendEmail}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <label>
                        <span className="text-input">
                          <i className="ttm-textcolor-skincolor ti-user"></i>
                          <input
                            type="text"
                            name="name"
                            placeholder="Full Name"
                            required="required"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-lg-6">
                      <label>
                        <span className="text-input">
                          <i className="ttm-textcolor-skincolor ti-email"></i>
                          <input
                            name="email"
                            type="email"
                            placeholder="Email Address"
                          />
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label>
                        <span className="text-input">
                          <i className="ttm-textcolor-skincolor ti-mobile"></i>
                          <input
                            name="phone"
                            pattern="^\+?\d{0,13}"
                            type="tel"
                            placeholder="Phone Number"
                            required="required"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-lg-6">
                      <label>
                        <span className="text-input">
                          <i className="ttm-textcolor-skincolor ti-qualification"></i>
                          <select name="qualification">
                            <option value="">Select Qualification</option>
                            <option>Plus Two</option>
                            <option>Degree</option>
                            <option>PG</option>
                          </select>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label>
                        <span className="text-input">
                          <i className="ttm-textcolor-skincolor ti-qualification"></i>
                          <select name="course">
                            <option value="">Select Course</option>
                            <option>B.Sc MLT</option>
                            <option>B.Sc RIT</option>
                            <option>DMLT</option>
                            <option>DDRIT</option>
                            <option>DMRT</option>
                            <option>X-Ray, CT Scan, MRI, ECG</option>
                            <option>Diploma In Civil Engineering</option>
                            <option>Diploma In Mechanical Engineering</option>
                            <option>Diploma In Electrical Engineering</option>
                          </select>
                        </span>
                      </label>
                    </div>
                    <div className="col-lg-6">
                      <label>
                        <span className="text-input">
                          <textarea
                            name="address"
                            cols="40"
                            rows="3"
                            placeholder="Enter Your Address "
                            required="required"
                          ></textarea>
                        </span>
                      </label>
                    </div>
                  </div>
                  <input
                    name="submit"
                    type="submit"
                    id="submit"
                    className="submit"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ttm-row introduction-section break-1199-colum clearfix">
        <div className="container">
          <div className="row">
            <div className="col-12 content-area">
              <div className="ttm-service-single-content-area">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="ttm-service-description">
                      <div className="section-title with-desc text-center clearfix">
                        <div className="container">
                          <h2 className="title">OUR SERVICES</h2>
                        </div>
                      </div>
                      <div className="container">
                        <div className="sep_holder_box width-100 mb-20">
                          <span className="sep_holder">
                            <span className="sep_line"></span>
                          </span>
                          <span className="sep_holder">
                            <span className="sep_line"></span>
                          </span>
                        </div>

                        <div className="row mt-15 mb-15">
                          <div className="col-sm-6">
                            <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey">
                              <li>
                                <i className="fa fa-arrow-circle-right"></i>
                                <span className="ttm-list-li-content">
                                  We give the best guidance and training for all
                                  students
                                </span>
                              </li>
                              <li>
                                <i className="fa fa-arrow-circle-right"></i>
                                <span className="ttm-list-li-content">
                                  We provide monthly updates of the progress of
                                  students to the concerned parents.
                                </span>
                              </li>
                              <li>
                                <i className="fa fa-arrow-circle-right"></i>
                                <span className="ttm-list-li-content">
                                  We assure 100% placement services for all our
                                  students.
                                </span>
                              </li>
                              <li>
                                <i className="fa fa-arrow-circle-right"></i>
                                <span className="ttm-list-li-content">
                                  All students will be monitored by competent
                                  teachers
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-sm-6">
                            <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor ttm-textcolor-darkgrey">
                              <li>
                                <i className="fa fa-arrow-circle-right"></i>
                                <span className="ttm-list-li-content">
                                  Practical facility will be provided for all
                                  courses.
                                </span>
                              </li>
                              <li>
                                <i className="fa fa-arrow-circle-right"></i>
                                <span className="ttm-list-li-content">
                                  We give necessary guidance and training for
                                  students who are backward in their studies.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Comments />
      <br></br>
    </div>
  );
};

export default Testimational;
