import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

export const Header = () => {
  const location = useLocation();
  React.useEffect(() => {
    document.getElementById("menu-trigger").addEventListener("click", () => {
      document.getElementById("menu").classList.toggle("active");
    });

    document.querySelectorAll(".dropdown-link").forEach((link) => {
      link.addEventListener("click", () => {
        link.nextElementSibling.classList.toggle("active");
      });
    });
  }, []);

  return (
    <div>
      <header id="masthead" className="header ttm-header-style-classicinfo">
        <div className="ttm-fbar-main-w ttm-fbar-position-right">
          <div className="ttm-fbar-box-w ttm-textcolor-white ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes">
            <div className="ttm-fbar-bg-layer ttm-bg-layer"></div>

            <div className="ttm-fbar-content-wrapper">
              <div className="ttm-fbar-box">
                <aside
                  id="ttm_widget_team_search-2"
                  className="widget-even widget-4 widget ttm_widget_team_search"
                >
                  <div className="team-search-form-w">
                    <form method="get" className="team-search-form " action="#">
                      <div className="ttm-team-search-title">
                        <h2>Doctors Search:</h2>
                      </div>
                      <div className="team-search-form-before-text">
                        We provide the most full medical services, so every
                        person could have the opportunity
                      </div>
                      <div className="ttm-fbar-input">
                        <div className="search_field by_name">
                          <i className="fa fa-user-md"></i>
                          <input
                            type="text"
                            placeholder="Search By Name"
                            name="s"
                            value=""
                          />
                        </div>
                      </div>
                      <div className="ttm-fbar-input">
                        <div className="search_field selectbox">
                          <i className="fa fa-tags"></i>
                          <select
                            name="team_group"
                            tabindex="-1"
                            className="select2-hidden-accessible"
                            aria-hidden="true"
                          >
                            <option value="" className="select-empty">
                              All Sections
                            </option>
                            <option value="dental">Dental</option>
                            <option value="dermatologist">Dermatologist</option>
                            <option value="health-care">Health Care</option>
                            <option value="ophthalmology">Ophthalmology</option>
                            <option value="psychological">Psychological</option>
                            <option value="surgery">Surgery</option>
                          </select>
                        </div>
                      </div>
                      <div className="submit_field">
                        <button type="submit">Search</button>
                      </div>
                    </form>
                  </div>
                </aside>

                <aside
                  id="enhancedtextwidget-10"
                  className="widget-odd widget-5 widget widget_text enhanced-text-widget"
                >
                  <h3 className="widget-title">Opening Hours:</h3>
                  <div className="textwidget widget-text">
                    These are our normal opening hours. When we are closed can
                    be found here.<br></br>
                    <div className="ttm-pricelistbox-wrapper ">
                      <ul className="ttm-pricelist-block">
                        <li>
                          Monday - Saturday
                          <span className="service-price">09.00 – 5.00</span>
                        </li>
                        <li>
                          Sunday
                          <span className="service-price">
                            <strong>Closed</strong>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </aside>

                <aside
                  id="enhancedtextwidget-11"
                  className="widget-even widget-6 widget widget_text enhanced-text-widget"
                >
                  <div className="textwidget widget-text">
                    <div className="featured-icon-box left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                          <i className="fa fa-phone"></i>
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h5>+123 456 78910 / 11</h5>
                        </div>
                        <div className="featured-desc">
                          <p>Have a question? call us now</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
          <div className="container">
            <div className="ttm-topbar-content">
              <ul className="top-contact text-left">
                <li>
                  <i className="themifyicon ti-location-pin"></i> Allianz
                  Institute [APT],Chelapallil Tower, Near SBI, Market Road,
                  Anchal - 691 306
                </li>
                <li>
                  <i className="themifyicon ti-timer"></i>Mon - Sat 9.00 - 5.00
                </li>
              </ul>
              <div className="topbar-right text-right">
                <div className="ttm-social-links-wrapper list-inline">
                  <ul className="social-icons">
                    <li>
                      <a
                        href="https://www.facebook.com/allianzcollege/"
                        target="_blank"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa fa-pinterest-p"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ttm-header-wrap">
          <div
            id="ttm-stickable-header-w"
            className="ttm-stickable-header-w ttm-bgcolor-white clearfix"
          >
            <div id="site-header-menu" className="site-header-menu">
              <div className="site-header-menu-inner ttm-stickable-header">
                <div className="container">
                  <div className="site-branding">
                    <a
                      href="/"
                      className="home-link"
                      title="allianz"
                      rel="home"
                    >
                      <img
                        id="logo-img"
                        className="site__branding__img"
                        src="images/logo-imgsss.webp"
                        alt="logo-img"
                      />
                    </a>
                  </div>

                  <div id="site-navigation" className="site-navigation">
                    <div className="ttm-menu-toggle">
                      <input type="checkbox" id="menu-toggle-form" />
                      <label
                        id="menu-trigger"
                        for="menu-toggle-form"
                        className="ttm-menu-toggle-block"
                      >
                        <span className="toggle-block toggle-blocks-1"></span>
                        <span className="toggle-block toggle-blocks-2"></span>
                        <span className="toggle-block toggle-blocks-3"></span>
                      </label>
                    </div>
                    <nav id="menu" class="menu">
                      <ul class="dropdown">
                        <li>
                          <a href="/">HOME</a>
                        </li>
                        <li>
                          <Link to="/AboutUs">ABOUT</Link>
                        </li>
                        <li>
                          <Link className="dropdown-link" exact to="#">
                            COURSES
                          </Link>
                          <ul>

                            {/* updated by radhul 17-03-2024 --start-- */}

                            {/* <li>
                              <Link to="/ParaCourse">PARAMEDICAL COURSES</Link>
                            </li>
                            <li>
                              <Link to="/TechCourse">TECHNICAL COURSES</Link>
                            </li> */}

                            {/* updated by radhul 17-03-2024 --end-- */}

                            <li>
                              <Link to="/ComputerCourse">COMPUTER COURSES</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link className="dropdown-link" exact to="#">
                            AFFILIATION
                          </Link>
                          <ul>
                            <li>
                              <a
                                href="https://exams.bharatsevaksamaj.org/main/states/"
                                target="_blank"
                              >
                                BSS
                              </a>
                            </li>
                            <li>
                              <a
                                href="http://www.whrde.edu.in/"
                                target="_blank"
                              >
                                WHRDE
                              </a>
                            </li>
                            <li>
                              <a href="http://graminskill.com/" target="_blank">
                                GSDM
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Link to="/ContactUs">CONTACT</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ttm-content-wrapper">
          <div className="container header__container">
            <div className="row">
              <div className="col-12">
                <div className="col__hide">
                  <div className="ttm-info-widget">
                    <img src="images/hut.webp" alt="" />
                    {location.pathname === "/" && (
                      <ul class="navbar-nav ml-auto">
                        <ScrollLink
                          exact
                          to="appointment__form"
                          spy={true}
                          smooth={true}
                          duration={500}
                          offset={-300}
                          className="nav-link btn__appointment"
                        >
                          <b style={{ color: "red" }}>APPOINTMENT</b>
                        </ScrollLink>
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <div className="slogan__wrapper">
                  <div className="label">Announcement</div>
                  <p className="slogan__text">
                    New admission started for computer courses. Paramedical and
                    technical courses also. All classes are conducted adhering
                    to the COVID – 19 regulations. For more details contact
                    9447183114.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
