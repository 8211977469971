import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../Header/Header";
import emailjs from "emailjs-com";
import swal from "sweetalert";

export const ContactUs = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_nqd8ey7",
        e.target,
        "user_y65onCSXuAI06uTgThxkw"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div>
      <Header />

      <div className="ttm-page-title-row">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">Contact Us</h1>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                      <span>Contact Us</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <section className="ttm-row bg-layer bg-layer-equal-height res-991-p-0 clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="ttm-col-bgcolor-yes ttm-bg ttm-bgcolor-skincolor ttm-textcolor-white spacing-8">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <h3>OUR OFFICE LOCATION</h3>
                    <div className="sep_holder_box width-100 pt-20 mb-35">
                      <span className="sep_holder">
                        <span className="sep_line"></span>
                      </span>
                      <span className="sep_holder">
                        <span className="sep_line"></span>
                      </span>
                    </div>

                    <div className="featured-icon-box iconalign-before-heading mb-25">
                      <div className="featured-content">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                            <i className="fa fa-map-marker"></i>
                          </div>
                        </div>
                        <div className="featured-title">
                          <h5>
                            Allianz Institute [APT],Chelapallil Tower, Near SBI,
                            Market Road{" "}
                          </h5>
                          <h4>Anchal</h4>
                        </div>
                      </div>
                    </div>

                    <div className="featured-icon-box iconalign-before-heading mb-25">
                      <div className="featured-content">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                            <i className="fa fa-envelope-o"></i>
                          </div>
                        </div>
                        <div className="featured-title">
                          <h5>allianzinstitute@gmail.com</h5>
                          <h4>Need support? Drop us an email</h4>
                        </div>
                      </div>
                    </div>

                    <div className="featured-icon-box iconalign-before-heading mb-25">
                      <div className="featured-content">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                            <i className="fa fa-phone"></i>
                          </div>
                        </div>
                        <div className="featured-title">
                          <h5>0475-2274191, 9447183114</h5>
                          <h4>Have Link question? call us now</h4>
                        </div>
                      </div>
                    </div>

                    <div className="featured-icon-box iconalign-before-heading mb-25">
                      <div className="featured-content">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                            <i className="fa fa-clock-o"></i>
                          </div>
                        </div>
                        <div className="featured-title">
                          <h5>Mon – Sat 09:00 – 5:00</h5>
                          <h4>We are open on</h4>
                        </div>
                      </div>
                    </div>
                    <div className="sep_holder_box width-100 pt-20 mb-30">
                      <span className="sep_holder">
                        <span className="sep_line"></span>
                      </span>
                      <span className="sep_holder">
                        <span className="sep_line"></span>
                      </span>
                    </div>
                    <div className="social-icons circle social-hover">
                      <ul className="list-inline">
                        <li className="social-facebook mr-2">
                          <a
                            className="tooltip-top"
                            target="_blank"
                            href="https://www.facebook.com/allianzcollege/"
                            data-tooltip="Facebook"
                          >
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="social-twitter mr-2">
                          <Link
                            className="tooltip-top"
                            target="_blank"
                            to="#"
                            data-tooltip="Twitter"
                          >
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li className="social-gplus mr-2">
                          <Link
                            className=" tooltip-top"
                            target="_blank"
                            to="#"
                            data-tooltip="Google+"
                          >
                            <i
                              className="fa fa-google-plus"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li className="social-linkedin">
                          <Link
                            className=" tooltip-top"
                            target="_blank"
                            to="#"
                            data-tooltip="LinkedIn"
                          >
                            <i
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="ttm-col-bgcolor-yes ttm-bg ttm-bgcolor-grey spacing-8">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>

                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">Get In Touch</h2>
                    </div>
                  </div>
                  <form
                    id="ttm-contactform"
                    className="ttm-contactform wrap-form clearfix"
                    onSubmit={sendEmail}
                  >
                    <label>
                      <span className="text-input">
                        <i className="ttm-textcolor-skincolor ti-user"></i>
                        <input
                          name="name"
                          type="text"
                          placeholder="Your Name"
                          required="required"
                        />
                      </span>
                    </label>
                    <label>
                      <span className="text-input">
                        <i className="ttm-textcolor-skincolor ti-mobile"></i>
                        <input
                          name="phone"
                          pattern="^\+?\d{0,13}"
                          type="tel"
                          placeholder="Cell Phone"
                          required="required"
                        />
                      </span>
                    </label>
                    <label>
                      <span className="text-input">
                        <i className="ttm-textcolor-skincolor ti-email"></i>
                        <input
                          name="email"
                          type="email"
                          placeholder="Email"
                          required="required"
                        />
                      </span>
                    </label>
                    <label>
                      <span className="text-input">
                        <i className="ttm-textcolor-skincolor ti-comment"></i>
                        <textarea
                          name="message"
                          cols="40"
                          rows="3"
                          placeholder="Message"
                          required="required"
                        ></textarea>
                      </span>
                    </label>
                    <input
                      name="submit"
                      type="submit"
                      id="submit"
                      className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-black"
                      value="SEND MESSAGE"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default ContactUs;
